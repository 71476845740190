<template>
  <div class="character transfer__wrapper">
    <form-layout
      :small-title="true"
      :title="$t('transfer.charactersTitle')"
      :sub-title="$t('transfer.charactersSubTitle')"
      :back="goToTransferMain"
    >
      <div class="transfer__form">
        <div class="transfer__form-content">
          <Input
            v-model.trim="nickname"
            icon="/base/user.svg"
            :placeholder="$t('transfer.nickname')"
            @input="findUsers"
          />
          <div class="transfer__form-characters onfw">
            <Loader v-if="isLoadedTransfer === false" />
            <div
              v-for="(value, key) in characters"
              :key="key"
              @click="selectCharacter(key, value.name, $event)"
              class="transfer__form-characters__item"
            >
              <p class="transfer__form-characters__item-title">{{ value.name }}</p>
              <img :src="value.imageUrl" alt="">
            </div>
            <div class="transfer__form-characters-not" v-if="characters.length === 0">
              <p>{{ $t('transfer.notFound') }}</p>
            </div>
          </div>
        </div>
        <Button
          class="transfer__form-btn"
          :disabled="!checked"
          @click="changeModal"
        >
          {{ $t('actions.continue') }}
        </Button>
      </div>
    </form-layout>
  </div>
</template>

<script>
import './transferCharacters.scss'

import Loader from '@/components/LoaderBar/LoaderBar'
import FormLayout from '@/components/Base/FormLayout/FormLayout.vue'
import Button from '@/components/Base/Button/Button.vue'
import Input from '@/components/Base/Input/Input.vue'

export default {
  name: 'TransferCharacters',
  components: {
    Input,
    Button,
    FormLayout,
    Loader
  },
  data () {
    return {
      isLoadedTransfer: true,
      robloxUserId: '',
      nickname: '',
      choosedNickname: '',
      isSelectCharacter: false,
      isFilled: false,
      characters: [],
      interval: null
    }
  },
  computed: {
    checked () {
      if (this.isSelectCharacter) return !this.isFilled
      return this.isFilled
    }
  },
  methods: {
    changeModal () {
      this.$store.commit('setScipGamepass', false)
      this.$emit('changeModal', {
        modal: 'TransferPrivatPlace',
        data: [this.robloxUserId, this.choosedNickname]
      })
    },
    goToTransferMain () {
      this.$emit('changeModal', {
        modal: 'MainForm',
        data: [this.robloxUserId, this.choosedNickname]
      })
    },
    selectCharacter (id, nickname, event) {
      if (this.isSelectCharacter) {
        const character = document.querySelector('.transfer__form-characters__item.select-ch')
        character.classList.remove('select-ch')
      }
      const target = event.target.closest('.transfer__form-characters__item')
      target.classList.add('select-ch')
      this.robloxUserId = id
      this.choosedNickname = nickname
      this.isSelectCharacter = true
    },
    findUsers () {
      this.isSelectCharacter = false
      clearTimeout(this.interval)

      this.interval = setTimeout(() => {
        if (this.nickname.length < 3) {
          this.characters = []
          return
        }

        this.isLoadedTransfer = false

        fetch(`https://transfer.backend-pier.com/search_users/${this.nickname}`)
          .then((res) => res.json())
          .then((data) => {
            this.isLoadedTransfer = true

            if (data.status) {
              this.characters = data.data
            } else {
              this.characters = []
            }
          })
          .catch((err) => console.log(err))
      }, 1000)
    }
  }
}
</script>
