<template>
  <div class="slider">
    <div class="slider__wrapper">
      <div class="slider__wrapper-lenta">
        <div class="slider__wrapper-item" v-for="slide in slides" :key="slide.img">
          <div class="slider__wrapper-item__content">
            <p class="slider__wrapper-item__title" v-html="$t(slide.title)" />
            <p class="slider__wrapper-item__text" v-html="$t(slide.text)" />
            <Button
              class="slider__wrapper-item__btn"
              right-icon="/slider/arrow.svg"
              @click="slide.submit"
            >
              {{currentSlide.btnText}}
            </Button>
          </div>
          <div class="slider__wrapper-item__img">
            <img :src="slide.img" :alt="slide.title">
          </div>
        </div>
      </div>
    </div>
    <div class="slider-circles">
      <div class="circle active" data-index="0"></div>
      <div class="circle" data-index="1"></div>
      <div class="circle" data-index="2"></div>
    </div>
    <div class="slider-mobile-footer">
      <Button
        class="slider__wrapper-item__btn-mobile"
        right-icon="/slider/arrow.svg"
        @click="currentSlide.submit"
      >
        {{currentSlide.btnText}}
      </Button>
    </div>
    <div class="slider__buy">
      <Button left-icon="/slider/shopping-bag.svg" @click="goToTransfer">{{ $t('actions.buyRobux') }}</Button>
    </div>
  </div>
</template>

<script>
import './slider.scss'
// import Slider from '@/utils/slider'
import Button from '@/components/Base/Button/Button.vue'
import SliderMixin from '@/components/Slider/SliderMixin'

export default {
  name: 'Slider',
  mixins: [SliderMixin],
  components: { Button },
  mounted () {
    // Slider()
  },
  data () {
    return {
      slides: [
        { title: 'slider.slide4.title', text: 'slider.slide4.content', submit: this.goToCompetition, btnText: this.$t('actions.participate'), img: require('@/assets/images/slider/banner_2.svg') },
        { title: 'slider.slide1.title', text: 'slider.slide1.content', submit: this.goToTransfer, btnText: this.$t('actions.try'), img: require('@/assets/images/slider/banner_3.svg') },
        // { title: 'slider.slide2.title', text: 'slider.slide2.conten submit: this.goToCompetition, btnText: '',, img: require('@/assets/images/slider/banner_2.svg') },
        { title: 'slider.slide3.title', text: 'slider.slide3.content', submit: this.goToFeedback, btnText: this.$t('actions.watch'), img: require('@/assets/images/slider/banner_1.svg') }
      ]
    }
  },
  computed: {
    currentSlide () {
      return this.slides[this.activeSlideIndex]
    }
  },
  methods: {
    goToTransfer () {
      this.$router.push('/transfer')
    },
    goToCompetition () {
      window.open('https://vk.com/robuxpier?w=wall-166771329_63298', '_blank')
    },
    goToFeedback () {
      window.open('https://vk.com/topic-166771329_38631003', '_blank')
    }
  }
}
</script>
